import {load} from "recaptcha-v3";

export default function captchaForm() {
    if (!window.config) {
        return;
    }

    const SITE_KEY = window.config.RECAPTCHA_SITE_KEY;

    if (!document.querySelector(".js-captcha-form")) {
        return;
    }

    load(SITE_KEY, {autoHideBadge: true}).then((recaptcha) => {
        const forms = document.querySelectorAll(".js-captcha-form");
        forms.forEach((form) => {
            form.addEventListener("submit", (event) => {
                event.preventDefault();
                const {action} = form.dataset;
                recaptcha.execute(action).then((token) => {
                    form.insertAdjacentHTML("afterbegin", `<input type="hidden" name="token" value="${token}">`);
                    form.submit();
                });
            });
        });
    });
}
