export default function navigationToggle() {
    const toggleButton = document.querySelector('.js-navtoggle');
    const html = document.querySelector('html');
    const body = document.querySelector('body');

    if (toggleButton) {
        toggleButton.addEventListener('click', () => {
            html.classList.toggle('scrolllock');
            body.classList.toggle('scrolllock');

            if (toggleButton.getAttribute('aria-expanded') === 'true') {
                toggleButton.setAttribute('aria-expanded', 'false');
                toggleButton.setAttribute('aria-label', 'Menü schließen');
            } else {
                toggleButton.setAttribute('aria-expanded', 'true');
                toggleButton.setAttribute('aria-label', 'Menü öffnen');
            }
        });
    }
}
