import navigationToggle from './modules/navigationToggle';
import captchaForm from "./modules/captchaForm";
import swiper from './modules/swiper';
import smoothscroll from 'smoothscroll-polyfill';
import smoothscrollAnchorPolyfill from 'smoothscroll-anchor-polyfill';

window.addEventListener('load', navigationToggle);
window.addEventListener('load', captchaForm);

window.onload = () => {
    swiper.init();
    smoothscroll.polyfill();
};
